var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"l-coupon",style:({ 'background-color': _vm.currentActivity.activityBackColor }),attrs:{"id":"l-l-coupon"}},[(_vm.ChannelModule.channel === 'plat_h5' && _vm.isShowBack)?_c('van-nav-bar',{ref:"limit",staticStyle:{"height":"0.9rem","background-color":"#fff"},attrs:{"title":"限时抢券","left-arrow":"","border":false,"fixed":"","placeholder":true},on:{"click-left":_vm.onClickLeft}}):_vm._e(),(_vm.activeId)?_c('van-row',{staticClass:"r-v-img"},[_c('van-image',{staticStyle:{"display":"block"},attrs:{"fit":_vm.imageFit,"width":"100%","height":"6rem","src":_vm.currentActivity.headImageUrl}}),(_vm.currentActivity.activityDescription)?_c('div',{staticClass:"r-v-hint",on:{"click":function($event){$event.preventDefault();_vm.showPopup = true}}},[_vm._v(" 活动规则 ")]):_vm._e()],1):_vm._e(),_c('div',{class:[{ 'l-c-swiper': _vm.isFixed }],style:({
      top:
        _vm.ChannelModule.channel === 'plat_h5'
          ? _vm.isFixed
            ? _vm.navHeight + 'px'
            : 0
          : 0,
    })},[(_vm.timeList.length > 0)?_c('LimitSwiper',{attrs:{"time-list":_vm.timeList,"bg-type":2,"num":_vm.num,"active-index":_vm.activeIndex},on:{"activeVolume":_vm.activeVolume}}):_vm._e()],1),_c('div',{ref:"lMan",staticClass:"l-main"},[_vm._l((_vm.currentActivity.activityCoupons),function(item,index){return _c('div',{key:index},[_c('Ticketltem',{class:{
          'btn-gray':
            (!(item.receivedType === '2') && _vm.activityState === '3') ||
            _vm.activityState === '0',
        },attrs:{"type":1,"status":_vm.dealName(item.receivedType, item.myReceivedCount).status,"par-value":_vm.dealPrice(item.parValue),"order-amount":_vm.dealAmount(item.orderAmountType, item.orderAmount),"effective-type":item.effectiveType,"coupon-name":item.couponName,"shop-name":item.shopName,"effective-date":item.expireTimeStr,"btn-name":_vm.dealName(item.receivedType, item.myReceivedCount).name,"is-vip":item.receiveUserType == 2 ? true : false,"quantity":_vm.dealQuantity(
            item.receiveQuantityType,
            item.receivedType,
            item.myReceivedCount,
            item.receiveQuantity
          )},on:{"onClick":function($event){return _vm.onClick(item)},"goDetail":function($event){return _vm.goDetail(item.couponId, item.expireTimeStr)}}})],1)}),(_vm.advertList.length > 0 && _vm.activeId)?_c('AdvertsSwiper',{attrs:{"advert-list":_vm.advertList},on:{"goAdvert":_vm.goAdvert}}):_vm._e()],2),_c('DefaultPopup',{attrs:{"title":"活动规则"},on:{"close":_vm.close},model:{value:(_vm.showPopup),callback:function ($$v) {_vm.showPopup=$$v},expression:"showPopup"}},[_c('div',{staticClass:"p-main",domProps:{"innerHTML":_vm._s(_vm.currentActivity.activityDescription)}})]),(_vm.activeId === '')?_c('LimitNull'):_vm._e(),_c('van-popup',{style:({ height: '100%', width: '100%' }),attrs:{"position":"right"},model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('CouponDetail',{attrs:{"coupon-id":_vm.couponId,"times":_vm.effectTime},on:{"close":function($event){_vm.showDetail = false}}})],1),_c('van-dialog',{attrs:{"confirm-button-text":"我知道了","show-confirm-button":true},on:{"confirm":_vm.confirm},model:{value:(_vm.showpPrize),callback:function ($$v) {_vm.showpPrize=$$v},expression:"showpPrize"}},[_c('HisroryNull',{attrs:{"title":_vm.title,"type":3}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }